a,
a:link,
a:visited {
  color: inherit;
}
a:hover {
  color: #5f9ea0;
}

/*Main styles*/
.researchPG {
  background-image: url(./research_images/Hexagon.svg);
  background-size: cover;
  background-position: center;
  color: #1c2833;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  height: calc(100% - 3.5rem);
  margin-top: 3.5rem;
}

@media (max-width: 850px) {
  .researchPG {
    margin-top: 0;
  }
}

/*Contents styles*/
.contentPG {
  padding-top: 6%;
  padding-left: 5%;
  padding-right: 7%;
  padding-bottom: 6%;
  overflow: overlay;
}

@media (max-width: 1200px) {
  .contentPG {
    padding-left: 4%;
    padding-right: 5%;
  }
}

@media (max-width: 1000px) {
  .contentPG {
    padding-left: 3.5%;
    padding-right: 4%;
  }
}

@media (max-width: 768px) {
  .contentPG {
    padding-left: 0;
    padding-right: 6%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
}
@media (max-width: 600px) {
  .contentPG {
    padding-right: 5%;
    margin-left: -1.5%;
  }
}

@media (max-width: 460px) {
  .contentPG {
    padding-top: 12%;
    padding-bottom: 10%;
    margin-left: -3%;
  }
}
@media (max-width: 350px) {
  .contentPG {
    padding-top: 18%;
    padding-bottom: 15%;
    margin-left: -7%;
  }
}

.title {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 3%;
}

.subtitle {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1%;
  padding-top: 3%;
}
.subtitle a:link,
.subtitle a:visited {
  color: inherit;
}
.subtitle a:hover {
  color: #6082b6;
}

.projTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1%;
  margin-top: 2.5%;
}
.projTitle a:link,
.projTitle a:visited {
  color: inherit;
}
.projTitle a:hover {
  color: #6082b6;
}

.topicTitle {
  font-size: 1.2rem;
  font-style: italic;
  text-decoration: underline;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}
.topicTitle a:link,
.topicTitle a:visited {
  color: inherit;
  background-color: #eaf2f8;
}
.topicTitle a:hover {
  color: inherit;
  background-color: #d6eaf8;
}

.mainText {
  font-size: 1.15rem;
  line-height: 1.75rem;
  text-align: justify;
  margin-bottom: 1.5%;
}
.mainText a:link {
  background-color: #eaf2f8;
  border-bottom: 1.7px solid lightgrey;
  font-style: italic;
  color: inherit;
}
.mainText a:visited {
  color: inherit;
  background-color: #eaf2f8;
  border-bottom: 1.7px solid lightgrey;
}
.mainText a:hover {
  color: inherit;
  background-color: #d6eaf8;
  border-bottom: 1.7px solid darkgrey;
}

.image {
  display: block;
  margin: auto;
  padding-bottom: 2.5%;
  padding-top: 0.5%;
  height: auto;
  width: auto;
  cursor: default;
}

@media (max-width: 768px) {
  .image {
    cursor: pointer;
  }
}

.note {
  font-style: italic;
  font-size: 1rem;
  color: #6082b6;
  text-align: right;
}
.note a:link {
  text-decoration: underline;
  color: inherit;
}
.note a:visited {
  color: inherit;
}
.note a:hover {
  color: #1c2833;
}

.list {
  margin-top: -0.9rem;
  margin-left: -1rem;
}

@media (max-width: 1200px) {
  .title {
    font-size: 2.75rem;
  }

  .subtitle {
    font-size: 1.75rem;
  }

  .projTitle {
    font-size: 1.25rem;
  }

  .topicTitle {
    font-size: 1rem;
  }
  .mainText {
    font-size: 1rem;
  }
  .note {
    font-size: 0.9rem;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .mainText {
    line-height: 1.5rem;
  }
}

@media (max-width: 850px) {
  .title {
    font-size: 2.25rem;
  }
  .subtitle {
    font-size: 1.25rem;
  }

  .projTitle {
    font-size: 1.1rem;
  }

  .topicTitle {
    font-size: 0.95rem;
  }
  .mainText {
    font-size: 0.9rem;
  }
  .note {
    font-size: 0.8rem;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 2rem;
    letter-spacing: 0;
  }
  .mainText {
    line-height: 1.25rem;
  }
  .list {
    margin-top: 0rem;
  }
}
@media (max-width: 460px) {
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.1rem;
  }

  .projTitle {
    font-size: 1rem;
  }

  .topicTitle {
    font-size: 0.9rem;
  }
  .mainText {
    font-size: 0.85rem;
  }
  .note {
    font-size: 0.7rem;
  }
}
@media (max-width: 350px) {
  .title {
    font-size: 1.3rem;
  }
  .subtitle {
    font-size: 1rem;
  }

  .projTitle {
    font-size: 0.9rem;
  }

  .topicTitle {
    font-size: 0.85rem;
  }
  .mainText {
    font-size: 0.75rem;
    line-height: 1.1rem;
  }
  .note {
    font-size: 0.6rem;
  }
}

/*Publication and reference styles*/
.reference {
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 1%;
  color: #1a5276;
}
.reference a:link,
.reference a:visited {
  color: inherit;
}
.reference a:hover {
  color: #6082b6;
}

.publication {
  margin-left: -1rem;
  font-size: 1.1rem;
  text-align: justify;
}

.publication a:link,
.publication a:visited {
  color: inherit;
}
.publication a:hover {
  color: #6082b6;
}

@media (max-width: 1200px) {
  .reference {
    font-size: 1rem;
  }
  .publication {
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  .reference {
    line-height: 1.25rem;
  }
}

@media (max-width: 850px) {
  .reference {
    font-size: 0.9rem;
  }
  .publication {
    font-size: 0.9rem;
  }
}

@media (max-width: 460px) {
  .reference {
    font-size: 0.85rem;
    line-height: 1.15rem;
  }
  .publication {
    font-size: 0.85rem;
    margin-left: -1.25rem;
  }
}

@media (max-width: 300px) {
  .reference {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .publication {
    font-size: 0.75rem;
    margin-left: -1.4rem;
  }
}
