.paintPage {
  background-image: url(../images/LightWool.png);
  background-repeat: repeat;
  margin-top: 3.5rem;
  height: calc(100% - 3.5rem);
  overflow: overlay;
}
.paintContent {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
}

.paintRow {
  justify-content: space-evenly;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.paintLong {
  max-width: 27%;
  height: auto;
  margin-bottom: 2.5rem;
  box-shadow: 3px 3px 6px dimgray;
  cursor: pointer;
}
.paintWide {
  max-width: 38%;
  height: auto;
  margin-bottom: 2.5rem;
  box-shadow: 3px 3px 6px dimgray;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .paintContent {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 850px) {
  .paintPage {
    margin-top: 0;
  }
  .paintContent {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .paintLong {
    margin-bottom: 2rem;
  }
  .paintWide {
    margin-bottom: 2rem;
  }
}

@media (max-width: 650px) {
  .paintLong {
    margin-bottom: 1rem;
  }
  .paintWide {
    margin-bottom: 1rem;
  }
}
