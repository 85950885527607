body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10px;
  overflow: hidden;
}
a {
  text-decoration-line: none;
  color: inherit;
}
a:link,
a:visited {
  color: inherit;
}
a:hover {
  color: #5f9ea0;
}
img {
  background-color: white;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(160, 160, 160, 0.6);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(200, 200, 200, 0.6);
}

@media (max-width: 850px) {
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.3rem;
  font-size: 70%;
}
sub {
  top: 0.1rem;
}
