/*Playlist styles*/
.playlist {
  font-size: 1.2rem;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 5%;
  padding-bottom: 7%;
}

@media (max-width: 1100px) {
  .playlist {
    font-size: 1.1rem;
    margin-top: 7%;
    padding-bottom: 10%;
  }
}

@media (max-width: 850px) {
  .playlist {
    font-size: 1rem;
    margin-top: 9%;
    padding-bottom: 12%;
  }
}

@media (max-width: 700px) {
  .playlist {
    line-height: 1.5rem;
    margin-top: 10%;
  }
}

@media (max-width: 550px) {
  .playlist {
    margin-top: 12%;
    padding-bottom: 15%;
  }
}

@media (max-width: 460px) {
  .playlist {
    font-size: 0.8rem;
    line-height: 1.3rem;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 15%;
    padding-bottom: 20%;
  }
}

@media (max-width: 350px) {
  .playlist {
    font-size: 0.7rem;
    line-height: 1.2rem;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

/*player styles*/
.playerBlock {
  display: flex;
  align-items: center;
  margin: 2%;
  margin-left: 4%;
  margin-right: 4%;
}

.playingInfo {
  width: 30%;
  padding-right: 2%;
}

/*controls styles*/
.playerControls {
  width: 40%;
  margin-left: 2%;
  margin-right: 2%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: darkgrey;
  font-size: 60px;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.prev:hover,
.next:hover {
  color: white;
}

.pause,
.play {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  box-shadow: none;
  color: darkgrey;
  font-size: 95px;
  cursor: pointer;
  margin-left: 12%;
  margin-right: 12%;
  padding: 0;
}

.play:hover,
.pause:hover {
  color: white;
}

.volumeControl {
  display: flex;
  width: 15%;
  margin-left: 15%;
  align-items: center;
}

.volumeBar {
  width: 80%;
  -webkit-appearance: none;
  background-color: transparent;
}

@media (max-width: 1100px) {
  .prev,
  .next {
    font-size: 55px;
  }
  .pause,
  .play {
    font-size: 90px;
  }
  .volumeControl {
    width: 18%;
    margin-left: 12%;
  }
}

@media (max-width: 850px) {
  .prev,
  .next {
    font-size: 45px;
  }
  .pause,
  .play {
    font-size: 80px;
  }
  .volumeControl {
    width: 20%;
    margin-left: 10%;
  }
}

@media (max-width: 700px) {
  .prev,
  .next {
    font-size: 40px;
  }
  .pause,
  .play {
    font-size: 70px;
    margin-left: 7%;
    margin-right: 7%;
  }
  .volumeControl {
    width: 22.5%;
    margin-left: 7.5%;
  }
  .volumeBar {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .prev,
  .next {
    font-size: 35px;
  }
  .pause,
  .play {
    font-size: 60px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .volumeControl {
    width: 25%;
    margin-left: 5%;
  }
  .volumeBar {
    width: 95%;
  }
}

@media (max-width: 460px) {
  .controls {
    justify-content: center;
  }
  .prev,
  .next {
    font-size: 30px;
  }
  .pause,
  .play {
    font-size: 50px;
    margin-left: 8%;
    margin-right: 8%;
  }

  .volumeBar {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .prev,
  .next {
    font-size: 25px;
  }
  .pause,
  .play {
    font-size: 40px;
    margin-left: 5%;
    margin-right: 5%;
  }
}

/*slider bar styles - general*/
::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 0.375rem;
  background: #5f6a6a;
  outline: none;
  border-radius: 0.25rem;
}

::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #fdfefe;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  margin-top: -0.3rem;
  box-shadow: 1px 1px 5px 2px rgb(20, 20, 20);
  cursor: pointer;
}

::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 0.35rem rgba(200, 200, 200, 0.4);
}

@media (max-width: 700px) {
  ::-webkit-slider-runnable-track {
    height: 0.35rem;
  }

  ::-webkit-slider-thumb {
    height: 0.8rem;
    width: 0.8rem;
    margin-top: -0.2rem;
  }
}

@media (max-width: 550px) {
  ::-webkit-slider-runnable-track {
    height: 0.3rem;
  }

  ::-webkit-slider-thumb {
    height: 0.7rem;
    width: 0.7rem;
  }
}

@media (max-width: 460px) {
  ::-webkit-slider-runnable-track {
    height: 0.25rem;
  }

  ::-webkit-slider-thumb {
    height: 0.6rem;
    width: 0.6rem;
    margin-top: -0.17rem;
  }
}

@media (max-width: 350px) {
  ::-webkit-slider-runnable-track {
    height: 0.2rem;
  }

  ::-webkit-slider-thumb {
    height: 0.5rem;
    width: 0.5rem;
    margin-top: -0.15rem;
  }
}

/*Progress Bar style*/
.progressBar {
  width: 40%;
  margin-bottom: 5%;
  margin-left: 30%;
  -webkit-appearance: none;
  background-color: transparent;
}
@media (max-width: 1100px) {
  .progressBar {
    margin-bottom: 6%;
  }
}

@media (max-width: 850px) {
  .progressBar {
    margin-bottom: 7%;
    margin-top: 2%;
  }
}

@media (max-width: 700px) {
  .progressBar {
    width: 44%;
    margin-left: 28%;
    margin-bottom: 8%;
  }
}
@media (max-width: 550px) {
  .progressBar {
    margin-bottom: 10%;
    margin-top: 3%;
  }
}

@media (max-width: 460px) {
  .progressBar {
    margin-top: 4%;
  }
}

/*songlist styles*/
.songlistBlock {
  background-image: linear-gradient(rgb(27, 31, 36), rgb(19, 20, 22));
  box-shadow: 1px 1px 20px rgb(8, 8, 8);
  padding-bottom: 2.5%;
}

.songlistHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid darkgrey;
  color: darkgrey;
  font-size: 85%;
  font-weight: bold;
  letter-spacing: 1px;
  border-width: 1px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 1.5%;
  padding-top: 2%;
  padding-bottom: 1%;
  padding-right: 0.5%;
  padding-left: 0.25%;
}

.songlist {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
}

.songlist:hover {
  background-image: linear-gradient(
    to right,
    rgba(165, 165, 165, 0.1),
    rgba(165, 165, 165, 0.2),
    rgba(165, 165, 165, 0.15),
    rgba(165, 165, 165, 0.1)
  );
  border-radius: 0.2rem;
  cursor: pointer;
}

.index {
  margin-left: 4%;
  width: 30%;
}

.songTitle {
  width: 70%;
}

.duration {
  width: 20%;
  text-align: center;
}

.durationLogo {
  font-size: 20px;
  width: 20%;
  text-align: center;
}

@media (max-width: 700px) {
  .durationLogo {
    font-size: 18px;
  }
}

@media (max-width: 550px) {
  .durationLogo {
    font-size: 15px;
  }
}

@media (max-width: 460px) {
  .durationLogo {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .durationLogo {
    font-size: 10px;
  }
}
