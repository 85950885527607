a,
a:link,
a:visited {
  color: inherit;
}
a:hover {
  color: #5f9ea0;
}
/*Wide header styles*/
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 3.5rem;
  color: #6b7575;
  box-shadow: 0 2px 10px 0 #acacac;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.header-name {
  flex-direction: row;
  display: flex;
  padding-left: 2rem;
  font-size: 1.1rem;
}
.header-nav {
  justify-content: space-around;
  flex-grow: 0.5;
  font-size: 0.9rem;
  display: flex;
  width: 20%;
  font-size: 0.825rem;
  padding-right: 0.5rem;
}

@media (max-width: 1200px) {
  .header-nav {
    width: 35%;
  }
}

@media (max-width: 1050px) {
  .header-nav {
    width: 55%;
    font-size: 0.8rem;
  }
  .header-name {
    padding-left: 1.5rem;
  }
}

/*For narrow header*/
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100vh - 3.5rem);
  position: absolute;
  z-index: 1200;
}
