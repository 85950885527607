.CVpage {
  height: calc(100% - 3.5rem);
  overflow: overlay;
  background-color: rgb(234, 238, 241);
  margin-top: 3.5rem;
}
.CVcontent {
  width: 65%;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 5%;
  background-image: url(../images/CanvasWhite.png);
  background-repeat: repeat;
  padding: 4.5%;
  box-shadow: 5px 5px 20px #acacac;
  font-size: 0.9rem;
}
.sec {
  margin-top: 4%;
}
.list {
  padding-bottom: 0.2%;
}
.emailPad {
  padding-bottom: 1%;
}
.CVTitle {
  color: rgb(48, 96, 151);
}
.secTitle {
  color: rgb(48, 96, 151);
}
.liTitle {
  color: rgb(33, 67, 105);
  margin-bottom: 0.5%;
  margin-top: 2%;
}
.experienceTitle {
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-top: 1%;
  margin-bottom: 1.75%;
}
.experienceSubtitle {
  color: rgb(90, 90, 90);
  font-size: 0.95rem;
  margin-top: 0.75%;
  font-weight: bold;
}
.liItem {
  color: slategrey;
  font-style: italic;
  font-size: 0.925rem;
  margin-top: 0.75%;
  font-weight: bold;
}
.container {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}
.pub {
  margin-left: -0.8rem;
}
.pub a:link,
.pub a:visited {
  color: black;
}
.pub a:hover {
  color: rgb(48, 96, 151);
}

@media (max-width: 1300px) {
  .CVcontent {
    width: 75%;
    font-size: 0.8rem;
  }
  .experienceTitle {
    font-size: 0.9rem;
  }
  .experienceSubtitle {
    font-size: 0.85rem;
  }
  .liItem {
    font-size: 0.825rem;
  }
}

@media (max-width: 1050px) {
  .CVcontent {
    width: 80%;
    margin-top: 1rem;
    margin-bottom: 1.5%;
    font-size: 0.7rem;
  }
  .experienceTitle {
    font-size: 0.8rem;
  }
  .experienceSubtitle {
    font-size: 0.75rem;
  }
  .liItem {
    font-size: 0.725rem;
  }
  .pub {
    margin-left: -1.2rem;
  }
}

@media (max-width: 850px) {
  .CVpage {
    margin-top: 0;
  }
  .CVcontent {
    margin-bottom: 2%;
    font-size: 0.65rem;
    width: 88%;
  }
  .experienceTitle {
    font-size: 0.75rem;
  }
  .experienceSubtitle {
    font-size: 0.7rem;
  }
  .liItem {
    font-size: 0.675rem;
  }
}

@media (max-width: 650px) {
  .CVcontent {
    margin-top: 0.5rem;
    font-size: 0.5rem;
    width: 90%;
    margin-bottom: 1%;
  }
  .experienceTitle {
    font-size: 0.55rem;
  }
  .experienceSubtitle {
    font-size: 0.5rem;
  }
  .liItem {
    font-size: 0.475rem;
  }
  .pub {
    margin-left: -1.5rem;
  }
}

@media (max-width: 470px) {
  .CVcontent {
    margin-top: 1%;
    font-size: 0.4rem;
  }
  .experienceTitle {
    font-size: 0.45rem;
  }
  .experienceSubtitle {
    font-size: 0.5rem;
  }
  .liItem {
    font-size: 0.45rem;
  }
}

@media (max-width: 380px) {
  .CVcontent {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}

@media (max-width: 320px) {
  .CVcontent {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .experienceTitle {
    font-size: 0.4rem;
  }
  .experienceSubtitle {
    font-size: 0.45rem;
  }
  .liItem {
    font-size: 0.4rem;
  }
}
